html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

body {
  max-height: 100vh;
  max-width: 100vw;
  margin: 0;
  font-family: JalopySans, sans-serif;
  overflow: hidden;
}

h1 {
  margin-top: 0;
  padding: 0;
}

h2 {
  margin-top: 0;
  padding: 0;
  font-size: 1.5rem;
}

a {
  color: #ff4500;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@font-face {
  font-family: Mavericks;
  font-style: normal;
  font-size: 48px;
  src: url("mavericks-book-webfont.d385de9a.ttf") format("truetype");
}

@font-face {
  font-family: JalopySans;
  font-style: normal;
  font-size: 48px;
  src: url("JalopySans.a67155ff.ttf") format("truetype");
}

.title-container {
  width: 100vw;
  background-color: #222;
  margin: 0;
  padding: 0;
}

.title {
  max-width: 800px;
  color: #fff;
  text-transform: uppercase;
  background-color: #222;
  margin: auto;
  padding: 18px;
  font-family: Mavericks, "sans-serif";
  font-size: 1.2rem;
}

.title-bold {
  font-weight: 600;
}

.title-menu {
  float: right;
  text-transform: none;
  text-align: center;
  min-width: 30px;
  min-height: 30px;
  display: inline-block;
}

.title-menu-item {
  margin: 0 10px 0 40px;
  display: none;
}

.title-highlighted {
  color: #222;
  background-color: #ff4500;
  border-radius: 4px;
  margin-left: 2px;
  padding: 5px;
  font-weight: bolder;
}

.menu {
  background: #fff;
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
  overflow: hidden;
}

.menu a {
  color: #222;
}

.menu a:hover {
  font-weight: 600;
  text-decoration: none;
}

.menu-item {
  padding: 8px;
}

.menu-icon {
  padding: 0 16px 0 0;
}

.menu-closed {
  max-width: 0;
  padding: 0;
  transition: width .5s cubic-bezier(0, .5, 0, 1), padding .5s cubic-bezier(0, .5, 0, 1);
}

.menu-open {
  width: 120px;
  padding: 20px;
  transition: width .5s cubic-bezier(0, .5, 0, 1), padding .5s cubic-bezier(0, .5, 0, 1);
}

.content {
  width: 100vw;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  margin: auto;
  overflow-y: scroll;
}

.footer {
  color: #fff;
  text-align: center;
  background-color: #222;
  padding: 16px 0;
  font-size: .85rem;
  display: grid;
}

.window {
  height: 217px;
}

.goodbots-image {
  width: 100%;
  height: 217px;
  background-image: url("goodbots-alt1.1149fa35.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.section0 {
  max-width: 800px;
  color: #fff;
  text-align: center;
  background-color: #222;
  margin: auto;
  padding: 20px 12px;
}

.section1 {
  max-width: 800px;
  color: #222;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #222;
  margin: auto;
  padding: 20px 12px;
}

.section2 {
  max-width: 800px;
  color: #222;
  text-align: center;
  background: #f4f4f4;
  border-bottom: 1px solid #222;
  margin: auto;
  padding: 20px 12px;
}

.section-continuous {
  border-bottom: 0;
}

.services-list {
  text-align: left;
  max-width: 80vw;
  grid-template-columns: auto auto;
  margin: 16px auto 0;
  font-size: 1.125rem;
  display: grid;
}

.service-item {
  margin: 4px 0;
}

.button-container {
  width: 70%;
  margin: auto;
  display: grid;
}

.button {
  color: #222;
  text-transform: uppercase;
  text-align: center;
  background-color: #8cf2e4;
  border: 1px solid #222;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1.1rem;
  display: inline-flex;
}

.form-input {
  border: 0 solid;
  border-bottom: 1px solid #222;
  border-radius: 0;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 1.125rem;
  display: grid;
}

.form-input:focus {
  outline: none;
}

.form-input-area {
  border: 1px solid #222;
  border-radius: 4px;
}

.label-less-form-input {
  margin-left: 20px;
}

.input-label {
  width: 90%;
  text-align: left;
  margin: auto;
  padding-left: 18px;
  display: grid;
}

.form-section-header {
  text-align: left;
  grid-column: 1 / span 2;
  margin-bottom: 6px;
  font-size: 1.2rem;
}

.form-section-radio {
  width: 90%;
  grid-template-columns: 100px 100px;
  margin: auto;
  padding-left: 25px;
  display: grid;
}

.input-radio-label {
  text-align: left;
  font-size: 1.125rem;
}

.input-radio {
  margin-right: 10px;
}

.form-input-label-empty {
  color: #666;
  font-size: 1.25rem;
  transition: top .5s cubic-bezier(0, .5, 0, 1), left .5s cubic-bezier(0, .5, 0, 1), font-size .5s cubic-bezier(0, .5, 0, 1);
  position: relative;
  top: 35px;
  left: 8px;
}

.form-input-label {
  color: #666;
  font-size: 1rem;
  transition: top .5s cubic-bezier(0, .5, 0, 1), left .5s cubic-bezier(0, .5, 0, 1), font-size .5s cubic-bezier(0, .5, 0, 1);
  position: relative;
  top: 0;
  left: 0;
}

.fasp {
  padding-top: 6px;
  padding-right: 8px;
}

.text {
  margin-bottom: 5px;
  font-size: 1.125rem;
  font-weight: lighter;
}

.hidden {
  display: none;
}

.margined {
  margin: auto;
}

.spin {
  animation-name: animation-spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes animation-spin {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 850px) {
  .menu, .title-menu {
    display: none;
  }

  .title-menu-item {
    display: inline-block;
  }

  .section1, .section2 {
    text-align: justify;
  }
}

/*# sourceMappingURL=index.b01dca85.css.map */
