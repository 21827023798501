/* color palette */
$black: black
$dark-gray: #222
$medium-gray: #666
$off-white: #f4f4f4
$white: white
$action: rgb(140, 242, 228)
$accent: orangered

/* palette mapping */
$bg0: $dark-gray
$text0: $white
$bg1: $white
$text1: $dark-gray
$bg2: $off-white
$text2: $dark-gray
$text-link: $accent
$text-label: $medium-gray


html
  color: $bg0
  font-size: 1em
  line-height: 1.4

body
  max-height: 100vh
  max-width: 100vw
  overflow: hidden
  margin: 0
  font-family: 'JalopySans', sans-serif

h1
  padding: 0
  margin-top: 0

h2
  padding: 0
  margin-top: 0
  font-size: 1.5rem

a
  text-decoration: none
  color: $text-link

a:hover
  text-decoration: underline

@font-face
  font-family: 'Mavericks'
  font-style: normal
  font-size: 48px
  src: url('../assets/mavericks-book-webfont.ttf') format('truetype')

@font-face
  font-family: 'JalopySans'
  font-style: normal
  font-size: 48px
  src: url('../assets/JalopySans.ttf') format('truetype')
