@import globals


.title-container
  width: 100vw
  background-color: $bg0
  padding: 0
  margin: 0

.title
  max-width: 800px
  margin: auto
  background-color: $bg0
  color: $text0
  font-family: "Mavericks", "sans-serif"
  font-size: 1.2rem
  padding: 18px
  text-transform: uppercase

.title-bold
  font-weight: 600

.title-menu
  float: right
  text-transform: none
  text-align: center
  min-width: 30px
  display: inline-block
  min-height: 30px

.title-menu-item
  margin: 0 10px 0 40px
  display: none

.title-highlighted
  background-color: $text-link
  padding: 5px
  color: $text1
  font-weight: bolder
  border-radius: 4px
  margin-left: 2px

.menu
  position: absolute
  top: 60px
  right: 0
  background: $bg1
  display: block
  overflow: hidden

.menu a
  color: $text1

.menu a:hover
  font-weight: 600
  text-decoration: none

.menu-item
  padding: 8px

.menu-icon
  padding: 0 16px 0 0

.menu-closed
  transition: width 500ms cubic-bezier(0, .5, 0, 1), padding 500ms cubic-bezier(0, .5, 0, 1)
  max-width: 0
  padding: 0

.menu-open
  transition: width 500ms cubic-bezier(0, .5, 0, 1), padding 500ms cubic-bezier(0, .5, 0, 1)
  width: 120px
  padding: 20px

.content
  width: 100vw
  margin: auto
  max-height: calc(100vh - 60px)
  min-height: calc(100vh - 60px)
  overflow-y: scroll

.footer
  font-size: 0.85rem
  background-color: $bg0
  color: $text0
  padding: 16px 0
  text-align: center
  display: grid

.window
  height: 217px

.goodbots-image
  width: 100%
  height: 217px
  background-image: url('../assets/goodbots-alt1.png')
  background-position: center
  background-size: contain
  background-repeat: no-repeat

.section0
  max-width: 800px
  margin: auto
  background-color: $bg0
  color: $text0
  text-align: center
  padding: 20px 12px

.section1
  max-width: 800px
  margin: auto
  background-color: $bg1
  color: $text1
  text-align: center
  padding: 20px 12px
  border-bottom: 1px solid $bg0

.section2
  max-width: 800px
  margin: auto
  background: $bg2
  color: $text2
  text-align: center
  padding: 20px 12px
  border-bottom: 1px solid $bg0

.section-continuous
  border-bottom: 0

.services-list
  display: grid
  grid-template-columns: auto auto
  font-size: 1.125rem
  text-align: left
  margin: 16px auto 0 auto
  max-width: 80vw

.service-item
  margin: 4px 0

.button-container
  display: grid
  width: 70%
  margin: auto

.button
  background-color: $action
  color: $dark-gray
  font-size: 1.1rem
  text-transform: uppercase
  text-align: center
  display: inline-flex
  padding: 10px 20px
  border: 1px solid $dark-gray
  border-radius: 4px

.form-input
  display: grid
  margin-bottom: 10px
  border: 0 solid
  border-radius: 0
  border-bottom: 1px solid $bg0
  padding: 8px
  font-size: 1.125rem

.form-input:focus
  outline: none

.form-input-area
  border: 1px solid $bg0
  border-radius: 4px

.label-less-form-input
  margin-left: 20px

.input-label
  width: 90%
  margin: auto
  text-align: left
  display: grid
  padding-left: 18px

.form-section-header
  font-size: 1.2rem
  grid-column: 1 / span 2
  text-align: left
  margin-bottom: 6px

.form-section-radio
  display: grid
  grid-template-columns: 100px 100px
  width: 90%
  margin: auto
  padding-left: 25px

.input-radio-label
  text-align: left
  font-size: 1.125rem

.input-radio
  margin-right: 10px

.form-input-label-empty
  transition: top .5s cubic-bezier(0, 0.5, 0, 1), left .5s cubic-bezier(0, 0.5, 0, 1), font-size .5s cubic-bezier(0, 0.5, 0, 1)
  font-size: 1.25rem
  position: relative
  top: 35px
  left: 8px
  color: $text-label

.form-input-label
  transition: top .5s cubic-bezier(0, 0.5, 0, 1), left .5s cubic-bezier(0, 0.5, 0, 1), font-size .5s cubic-bezier(0, 0.5, 0, 1)
  position: relative
  top: 0
  left: 0
  font-size: 1rem
  color: $text-label

.fasp
  padding-right: 8px
  padding-top: 6px

.text
  font-size: 1.125rem
  font-weight: lighter
  margin-bottom: 5px

.hidden
  display: none

.margined
  margin: auto

.spin
  animation-timing-function: linear
  animation-name: animation-spin
  animation-duration: 2s
  animation-iteration-count: infinite

@keyframes animation-spin
  100%
    transform: rotate(360deg)

@media screen and (min-width: 850px)
  .menu
    display: none

  .title-menu
    display: none

  .title-menu-item
    display: inline-block

  .section1
    text-align: justify

  .section2
    text-align: justify